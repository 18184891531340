import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FaBalanceScaleRight } from "react-icons/fa";


export default function OurProcess() {
  return (
    <section className="bg-gray-100 py-16 px-4">
      <h2 className="text-center">Why Hire Me?</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-8 max-w-6xl mx-auto">
        <div className="text-center">
          <StaticImage src="../images/whyhire1.png" placeholder="none" height={200} alt="20+ Years of Experience" />
          <h3 className="pt-4">20+ Years of Experience</h3>
          <p>ERISA is a complicated area of the law, and you want to hire a lawyer that knows the system inside and out.  I have years of experience handling ERISA claims and <em>only</em> ERISA claims.</p>
        </div>
        <div className="text-center">
          <StaticImage src="../images/whyhire2.png" placeholder="none" height={200} alt="The Consultation Is Free" />
          <h3 className="pt-4">The Consultation Is Free</h3>
          <p>I'll meet with you and help you understand the situation and what your rights are -- even if you don't end up hiring me.  </p>
        </div>
        <div className="text-center">
          <StaticImage src="../images/whyhire3.png" placeholder="none" height={200} alt="Fight Back" />
          <h3 className="pt-4">Fight Back</h3>
          <p>Insurance companies are counting on the idea that you'll get frustrated with the process and not hold them accountable.  I can help you fight back.</p>
        </div>
      </div>
      <div className="text-center pt-6">
        <Link to="/free-case-evaluation" className="btn btn-secondary">
          Start Your Free Case Evaluation
          <FaBalanceScaleRight className="inline ml-3 -mt-1 text-2xl" />
        </Link>
      </div>
    </section>
  )
}