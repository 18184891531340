import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Faqs from "../components/faqs"
import Cta from "../components/cta"
import OurProcess from "../components/our-process"
import BlockContent from "../components/block-content"
import TextContainer from "../components/text-container"

export const query = graphql`
query erisaPageQuery {
  page:sanityPage(_id: {eq: "erisaPage"}) {
    title
    _rawText
    seo {
      title
      description
    }
  }
}

`;

const ErisaPage = ({ data }) => {
  const page = data.page || {};
  console.log(page)
  return (
    <Layout title={page.seo?.title || page.title} description={page.seo?.description || ""} location="">
      <div className="py-16">
        <TextContainer>
          <h1>{page.title}</h1>
          <BlockContent blocks={page._rawText} />
        </TextContainer>
      </div>
      <Faqs />
      <OurProcess />
      <Cta />
    </Layout>
  )
}

export default ErisaPage
