import React from "react";
import { CtaForm } from "./cta-form";
import TextContainer from "./text-container";

export default function Cta({ title, subtitle }) {
  return (
    <>
      <section className='py-16'>
        <TextContainer>
          <div className='text-center pb-6'>
            <h2>{title ? title : "Need ERISA Help?"}</h2>
            <p>{subtitle ? subtitle : "We have 20+ years experience handling ERISA cases"}</p>
          </div>
          <CtaForm />
        </TextContainer>
      </section>
    </>
  )
}