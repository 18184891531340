import React from 'react'
import { Disclosure } from '@headlessui/react'
import { FaChevronDown } from 'react-icons/fa'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BlockContent from './block-content'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Faqs() {

  const data = useStaticQuery(graphql`
  query FaqsQuery {
    sanityFaqPage(_id: {eq: "faqPage"}) {
      faqs {
        question
        _rawAnswer
      }
    }
  }
  
  `)

  const { faqs } = data.sanityFaqPage
  return (
    <section className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently Asked Questions</h2>
          <dl className="mt-6 space-y-6">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="py-6 pl-4 pr-4 bg-gray-100 border rounded-md">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-bold text-primary">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <FaChevronDown
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-4 pr-12">
                      <BlockContent blocks={faq._rawAnswer} />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>

        </div>
      </div>
    </section>
  )
}